<template>
    <div class="print-body">
        <table width="100%" border="1" cellspacing="0">
            <tr style="height: 30px">
                <td colspan="6">
                    <div class="print-title">
                        售后退换货申请单
                    </div>
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" colspan="1">
                    发货通知单编号：<span>{{aftermarketForm.sendNoticeNum}}</span>
                </td>
                <td style="text-align: right" colspan="2">
                    编号：<span style="color:red;">{{aftermarketForm.applyNum}}</span>
                </td>
            </tr>
            <tr style="height: 30px">
                <td class="text-style">
                    客户名称：<span>{{aftermarketForm.companyName}}</span>
                </td>
                <td class="text-style" style="width: 120px">
                    申请人：<span>{{aftermarketForm.applyUserName}}</span>
                </td>
                <td class="text-style" style="width: 150px">
                    现场处理：<span>{{aftermarketForm.siteUserName?aftermarketForm.siteUserName:'无'}}</span>
                </td>
            </tr>
            <tr style="height: 30px">
                <td class="text-style" style="width: 170px" colspan="3">
                    发货信息：<span>{{aftermarketForm.logistic}}</span>
                </td>
            </tr>
            <tr style="height: 30px">
                <td class="text-style" style="width: 170px" colspan="3">
                    故障描述：<span>{{aftermarketForm.trouble}}</span>
                </td>
            </tr>
            <tr style="height: 30px">
                <td class="text-style" style="width: 170px" colspan="3">
                    备注：<span>{{aftermarketForm.remark}}</span>
                </td>
            </tr>
            <tr style="height: 30px">
                <td colspan="3">
                    <div>
                        <table width="100%" style="text-align: left">
                            <!--                            <tr>-->
                            <!--                                <td style="width: 120px">1. 是否在保质期:</td>-->
                            <!--                                <td style="width: 80px;">□ 是</td>-->
                            <!--                                <td style="width: 80px;">□ 否</td>-->
                            <!--                            </tr>-->
                            <!--                            <tr>-->
                            <!--                                <td style="width: 120px;">2. 售后更换方式:</td>-->
                            <!--                                <td style="width: 80px;">□ 客户自换</td>-->
                            <!--                                <td style="width: 80px;">□ 现场更换</td>-->
                            <!--                            </tr>-->
                            <!--                            <tr>-->
                            <!--                                <td style="width: 120px;">3. 远程处理结果:</td>-->
                            <!--                            </tr>-->
                            <tr>
                                <td style="width: 120px">1. 是否在保质期:</td>
                                <td style="width: 80px;">{{aftermarketForm.isInWarranty==0?"否":'是'}}</td>
                            </tr>
                            <tr>
                                <td style="width: 120px">2. 售后更换方式:</td>
                                <td style="width: 80px;">{{aftermarketForm.changeMethod==0?"客户自换":'现场更换'}}</td>
                            </tr>
                            <tr>
                                <td style="width: 120px">3. 远程处理结果:</td>
                                <td style="width: 80px;">{{aftermarketForm.remoteDealResult}}</td>
                                <td style="width: 50px">工程师:</td>
                                <td style="width: 80px;">{{aftermarketForm.remoteDealUserName}}</td>
                                <td style="width: 35px">时间:</td>
                                <td style="width: 120px;">{{aftermarketForm.dealDate}}</td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr style="height: 30px">
                <td colspan="3">
                    <div>
                        <table width="100%" border="1" cellspacing="0" height="70%">
                            <thead>
                            <tr>
                                <th>设备类型</th>
                                <th>IMEI号</th>
                                <th>SIM卡号</th>
                                <th>是否吊钩可视化</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in imeiList" :key="index">
                                <td>{{item.deviceType==0?'塔机':'升降机'}}</td>
                                <td>{{item.imeiNum}}</td>
                                <td>{{item.simNum}}</td>
                                <td>{{item.isVisual==0?'否':'是'}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%" border="1" cellspacing="0" height="70%" style="margin-top:10px">
                            <thead>
                            <tr>
                                <th>物料编码</th>
                                <th>适用机型</th>
                                <th>部件</th>
                                <th>配件名称</th>
                                <th>型号</th>
                                <th>数量</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in aftermarketForm.materialList" :key="index">
                                <td>{{item.materialCode}}</td>
                                <td>{{item.useTypeName}}</td>
                                <td>{{item.componentName}}</td>
                                <td>{{item.materialName}}</td>
                                <td>{{item.model}}</td>
                                <td>{{item.count}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>

        </table>
    </div>
</template>

<script>
    var qs = require('qs');
    export default {
        data() {
            return {
                tableData: [],
                remarkList: [],
                aftermarketForm: {
                    number: 'SH20210809001'
                },
                imeiList:[],
            }
        },
        mounted() {
            window.print()
        },
        created() {
            var dataObj = qs.parse(localStorage.getItem("shippingData"))
            this.aftermarketForm = {
                sendNoticeNum:dataObj.sendNoticeNum,
                applyNum: dataObj.applyNum,
                applyUserName: dataObj.applyUserName,
                changeMethod: dataObj.changeMethod,
                companyName: dataObj.companyName,
                contact: dataObj.contact,
                contactTel: dataObj.contactTel,
                contractNum: dataObj.contractNum,
                dealDate: dataObj.dealDate,
                isInWarranty: dataObj.isInWarranty,
                logistic: dataObj.logistic,
                materialList: dataObj.materialList,
                projectAddress: dataObj.projectAddress,
                remark: dataObj.remark,
                remoteDealResult: dataObj.remoteDealResult,
                remoteDealUserName: dataObj.remoteDealUserName,
                siteUserName: dataObj.siteUserName,
                trouble: dataObj.trouble
            };
            this.imeiList = dataObj.imeiList;
        }

    }
</script>

<style lang="scss" scoped>
    .print-body {
        text-align: center;
        margin: 0 auto;
        width: 715px;
        height: 1123px
    }

    td {
        padding: 5px;
    }

    .print-title {
        font-size: 26px;
        font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
        font-weight: bold;
    }

    .text-style {
        text-align: left;
        font-weight: bolder;
        font-family: '微软雅黑';
    }
</style>